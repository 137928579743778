import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"
import linkResolver from "../utils/linkresolver"

function PreviewPage({ isPreview, isLoading }) {
  if (isPreview === false) return "This is not a preview."

  return (
    <div className="flex items-center justify-center h-screen">
      <p>Loading</p>
    </div>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: "paigemcclanahancom",
  linkResolver,
})
