const linkResolver = ({ node, key, value }) => doc => {
  if (doc.type === "home") {
    return `/`
  } else if (doc.type === "newsletter") {
    return `/newsletter`
  } else if (doc.type === "clips") {
    return `/clips`
  } else if (doc.type === "privacy") {
    return `/privacy-policy`
  } else if (doc.type === "legal_notice") {
    return `/legal-notice`
  } else if (doc.type === "blog_post") {
    return `/blog/${doc.uid}`
  } else if (doc.type === "newsletter_issue") {
    return `/object-preview`
  } else if (doc.type === "external_article") {
    return `/object-preview`
  } 
  return `/${doc.uid}`
}

module.exports = linkResolver
